var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-8",attrs:{"id":"IotEdgeView","fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('div',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Iot Edge List")]),_c('div',{staticClass:"text-subtitle-1 font-weight-medium"},[_vm._v(" Total "+_vm._s(_vm.total_edges)+" edges ")])]),_c('v-btn',{attrs:{"small":"","depressed":"","color":"primary"},on:{"click":_vm.navigateToCreateIotEdge}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" New IoT Edge ")],1)],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 cursor-pointer",attrs:{"disable-sort":"","headers":_vm.headers,"items":_vm.edge_list,"items-per-page":10,"footer-props":{ 'disable-items-per-page': true }},on:{"click:row":_vm.rowDataSelected,"pagination":_vm.paginationUpdated},scopedSlots:_vm._u([{key:"item.edgeName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(item.enabledHA)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-shield-check")])]}}],null,true)},[_c('span',[_vm._v("Enable High Availability Mode")])]):_vm._e(),_vm._v(" "+_vm._s(item.edgeName)+" ")],1),(item.edgeStandbyName)?_c('div',{staticClass:"text-caption grey--text text-lighten-1"},[_vm._v(" Stand by: "+_vm._s(item.edgeStandbyName)+" ")]):_vm._e()]}},{key:"item.siteId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.siteId.siteName)+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }