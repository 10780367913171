<template>
  <v-container id="IotEdgeView" class="px-8" fluid>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <div>
            <div class="text-h5 font-weight-bold">Iot Edge List</div>
            <div class="text-subtitle-1 font-weight-medium">
              Total {{ total_edges }} edges
            </div>
          </div>
          <v-btn
            small
            depressed
            color="primary"
            @click="navigateToCreateIotEdge"
          >
            <v-icon left> mdi-plus </v-icon>
            New IoT Edge
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- <v-row class="py-0">
      <v-col cols="9">
        <div class="primary--text">Sort By</div>
      </v-col>
      <v-col cols="3">
        <v-text-field dense rounded label="Search" outlined></v-text-field>
      </v-col>
    </v-row> -->

    <v-row>
      <v-col cols="12">
        <v-data-table
          class="elevation-1 cursor-pointer"
          disable-sort
          :headers="headers"
          :items="edge_list"
          :items-per-page="10"
          :footer-props="{ 'disable-items-per-page': true }"
          @click:row="rowDataSelected"
          @pagination="paginationUpdated"
        >
          <template v-slot:item.edgeName="{ item }">
            <div class="d-flex">
              <v-tooltip v-if="item.enabledHA" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    class="mr-1"
                    >mdi-shield-check</v-icon
                  >
                </template>
                <span>Enable High Availability Mode</span>
              </v-tooltip>
              {{ item.edgeName }}
            </div>
            <div
              v-if="item.edgeStandbyName"
              class="text-caption grey--text text-lighten-1"
            >
              Stand by: {{ item.edgeStandbyName }}
            </div>
          </template>

          <template v-slot:item.siteId="{ item }">
            {{ item.siteId.siteName }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "IotEdgeView",
  data() {
    return {
      headers: [
        { text: "ID", value: "_id" },
        {
          text: "Edge Name",
          align: "start",
          value: "edgeName",
        },
        {
          text: "Site Name",
          align: "start",
          value: "siteId",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      edge_list: (state) => state.edge.edge_list,
      total_edges: (state) => state.edge.total_edges,
      current_page: (state) => state.edge.current_page,
    }),
  },
  methods: {
    ...mapActions({
      requestGetIotEdgeList: "requestGetIotEdgeList",
    }),
    ...mapMutations({
      resetSiteState: "RESET_SITE_STATE",
      setFilterPage: "SET_EDGE_FILTER_PAGE",
    }),
    rowDataSelected(row) {
      this.navigateToIotEdgeDetail(row._id);
    },
    paginationUpdated(pagination) {
      if (
        pagination.page === pagination.pageCount &&
        pagination.pageStop < this.total_sites
      ) {
        this.setFilterPage(this.current_page + 1);
        this.requestGetIotEdgeList();
      }
    },
    navigateToCreateIotEdge() {
      this.$router.push({ name: "edge-create" });
    },
    navigateToIotEdgeDetail(device_id) {
      this.$router.push({ name: "edge-detail", params: { id: device_id } });
    },
  },
  async beforeMount() {
    await this.requestGetIotEdgeList();
  },
  beforeRouteLeave(to, from, next) {
    this.resetSiteState();
    next();
  },
};
</script>
